import axios from '@/utils/request'

// 交易概况
export function transactionSituation (params) {
  // params.loading = true
    return axios({
      url: `/manager/data/tradesurvey`,
      method: 'get',
      params
    })
  }

  // 收益概况
export function profitsurveySituation (params) {
  // params.loading = true
  return axios({
    url: `/manager/data/profitsurvey`,
    method: 'get',
    params
  })
}
  // 机构汇总
export function getOrganizations (params) {
  return axios({
    url: `/manager/data/summary/organizations`,
    method: 'get',
    params
  })
}
  // 机构汇总-详情
export function getOrganizationsDetail (params) {
  return axios({
    url: `/manager/data/summary/organizations/detail`,
    method: 'get',
    params
  })
}
 // 渠道汇总
export function getMchchannels (params) {
  return axios({
    url: `/manager/data/summary/mchchannels`,
    method: 'get',
    params
  })
}
 // 渠道汇总-详情
export function getMchchannelsDetail (params) {
  return axios({
    url: `/manager/data/summary/mchchannels/detail`,
    method: 'get',
    params
  })
}
 // 商户汇总
export function getMerchants (params) {
  return axios({
    url: `/manager/data/summary/merchants`,
    method: 'get',
    params
  })
}
 // 商户汇总-详情
export function getMerchantsDetail (params) {
  return axios({
    url: `/manager/data/summary/merchants/detail`,
    method: 'get',
    params
  })
}
 // 代理商汇总
export function getAgents (params) {
  return axios({
    url: `/manager/data/summary/agents`,
    method: 'get',
    params
  })
}
 // 代理商汇总-详情
export function getAgentsDetail (params) {
  return axios({
    url: `/manager/data/summary/agents/detail`,
    method: 'get',
    params
  })
}