<template>
  <div id="mian">
    <div>
      <div class="con_from">
        <div class="lineP">
          <div class="item">
            <i>渠道名称</i>
            <el-input
              v-model="query.keyword"
              suffix-icon="el-icon-search"
              placeholder="渠道名称/渠道商户号"
            ></el-input>
          </div>
          <div class="item">
            <i>产品类型</i>
            <el-select
              v-model="query.productId"
              placeholder="全部"
            >
              <el-option label="全部" value=""></el-option>
              <el-option
                v-for="(v, i) in productDrop"
                :key="i"
                :label="v.productName"
                :value="v.productId"
              >
              </el-option>
            </el-select>
          </div>
          <div class="item">
            <i>产品通道</i>
            <el-select
              v-model="query.payChannelId"
              placeholder="全部"
            >
              <el-option label="全部" value=""></el-option>
              <el-option
                v-for="(v, i) in payChannelDrop"
                :key="i"
                :label="v.payChannelName"
                :value="v.payChannelId"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="lineP">
          <div class="item itemTime">
            <i>账单日期</i>
            <el-date-picker
              v-model="query.startDate"
              value-format="yyyy-MM-dd"
              type="date"
              placeholder="选择日期"
            >
            </el-date-picker>
            <span style="padding-left: 10px; padding-right: 10px">-</span>
            <el-date-picker
              v-model="query.endDate"
              value-format="yyyy-MM-dd"
              type="date"
              placeholder="选择日期"
            >
            </el-date-picker>
          </div>
          <div class="itembtn">
            <el-button class="searchBt" @click="getLists">查询</el-button>
          </div>
        </div>
      </div>
      <div class="tab1">
        <table class="tab">
          <tr class="tab_title">
            <td>
              <p>账单日期</p>
            </td>
            <td>
              <p>渠道名称</p>
              <p>渠道商户号</p>
            </td>
            <td>
              <p>产品类型</p>
            </td>
            <td>
              <p>产品通道</p>
            </td>
            <td>
              <p>交易笔数(笔)</p>
            </td>
            <td>
              <p>交易金额(元)</p>
            </td>
            <td>
              <p>退款笔数(笔)</p>
            </td>
            <td>
              <p>退款金额(元)</p>
            </td>
            <td>
              <p>手续费(元)</p>
            </td>
            <td>
              <p>结算金额(元)</p>
            </td>
          </tr>
          <tr v-for="(v, i) in tabData" :key="i">
            <td>
              <p>{{ v.settleDate }}</p>
            </td>
            <td>
              <p>{{ v.xname }}</p>
              <p>{{ v.xid }}</p>
            </td>
            <td>
              <p>{{ v.productName }}</p>
            </td>
            <td>
              <p>{{ v.payChannelName }}</p>
            </td>
            <td>
              <p>{{ v.tradeNum }}</p>
            </td>
            <td>
              <p>{{ (v.tradeAmount / 100) | formatMoney }}</p>
            </td>
            <td>
              <p>{{ (v.refundNum / 100) | formatMoney }}</p>
            </td>
            <td>
              <p>{{ (v.refundAmount / 100) | formatMoney }}</p>
            </td>
            <td>
              <p>{{ (v.fee / 100) | formatMoney }}</p>
            </td>
            <td>
              <p>{{ (v.settleAmount / 100) | formatMoney }}</p>
            </td>
          </tr>
        </table>
      </div>
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="query.pageNo"
        :page-sizes="[10, 20, 50,100]"
        :page-size="query.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalNum"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { getMchchannelsDetail } from "@/api/data/clearing.js";
import { payproductsDrop, paychannelsDrop } from "@/api/common.js";
import { formatDate } from "@/utils/common.js";
export default {
  data() {
    return {
      query: {
        pageNo: 1,
        pageSize: 10,
        startDate: formatDate(+new Date() - 24 * 60 * 60 * 1000, "yyyy-MM-dd"),
        endDate: formatDate(+new Date() - 24 * 60 * 60 * 1000, "yyyy-MM-dd"),
        keyword: "",
        productId: "",
        payChannelId: "",
      },
      tabData: [],
      productDrop: [],
      payChannelDrop: [],
      totalNum: 0,
      crumbs: "批量订单查询",
    };
  },
  created() {
    if (this.$route.query.id) {
      this.query.keyword = this.$route.query.id;
      this.query.startDate = this.$route.query.startDate
        ? this.$route.query.startDate
        : "";
      this.query.endDate = this.$route.query.endDate
        ? this.$route.query.endDate
        : "";
    }
    this.getLists();
    this.getDrop();
  },
  methods: {
    // 获取列表详情
    getLists() {
      this.query.startDate = this.query.startDate ? this.query.startDate : "";
      this.query.endDate = this.query.endDate ? this.query.endDate : "";
      this.query.keyword = this.query.keyword.trim();
      if (!this.query.startDate || !this.query.endDate) {
        this.$message.error("账单日期时间段必选");
        return;
      }
      getMchchannelsDetail(this.query).then((res) => {
        if (res.resultStatus) {
          this.tabData = res.resultData.settles;
          this.totalNum = res.resultData.totalNum;
          if (this.tabData.length == 0) {
            this.$message({
              message: "暂无数据",
              duration: 1500,
            });
          }
        }
      });
    },
    // 获取下拉
    getDrop() {
      payproductsDrop().then((res) => {
        if (res.resultStatus) {
          this.productDrop = res.resultData;
        }
      });
      paychannelsDrop().then((res) => {
        if (res.resultStatus) {
          this.payChannelDrop = res.resultData;
        }
      });
    },
    // 页面大小改变
    handleSizeChange(size) {
      this.query.pageSize = size;
      this.getLists();
    },
    handleCurrentChange(page) {
      this.query.pageNo = page;
      this.getLists();
    },
  },
};
</script>
<style scoped>

</style>
